import { RiArrowDownSFill } from "@react-icons/all-files/ri/RiArrowDownSFill";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Select, TextField, Checkbox } from "react-md";
import TimezonePicker from "react-bootstrap-timezone-picker";
import {
  OUTSIDE_US_OPTIONS,
  US_STATES_CODES,
} from "../../constants/states";

function SiteInfoForm(props) {
  const { control, register } = useForm();
  const {
    isDistrict,
    school,
    streetAddress,
    schoolOrDistrictName,
    country,
    city,
    state,
    isSubmitting,
    stateOrProvince,
    postalCode,
    timezone,
    contactName,
    contactEmail,
    locked,
    sendToOrders,
    forceNewEstimate,
  } = props;
  const {
    handleStateOrProvinceChange,
    setStateOrProvince,
    setCountry,
    setSchool,
    setTimezone,
    setContactEmail,
    setContactName,
    setStreetAddress,
    setPostalCode,
    setCity,
    setSendToOrders,
    setForceNewEstimate,
    billingAddress,
    setBillingAddress,
    showBillingAddress,
    setShowBillingAddress,
    handleBillingAddressStateOrProviceChange,
  } = props;

  // Locked is used when we loaded information from NCES id so we dont want to allow them to change any data
  return (
    <>
      {(state !== "" && schoolOrDistrictName.name) ||
        OUTSIDE_US_OPTIONS.indexOf(state) >= 0 ? (
        <>
          <div id="school-row-id-2" className="row">
            <div id="schoolName-column" className="schoolColumn">
              <TextField
                id="schoolNametextfield"
                name="qrSchoolNametextfield"
                label={isDistrict ? "District Name" : "School Name"}
                onChange={(e) => setSchool(e.target.value)}
                value={school}
                ref={register({
                  required:
                    "Please enter a " +
                    (isDistrict ? "district" : "school") +
                    " name.",
                })}
                disabled={
                  (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                  schoolOrDistrictName.name.indexOf("not found") === -1
                }
              />
            </div>
          </div>
          <div id="school-row-id-7" className="row">
            <div id="schoolAddress-column" className="streetAddressColumn">
              <TextField
                id="streetAddressTextfield"
                name="qrStreetAddressTextfield"
                label="Street Address"
                onChange={(e) => setStreetAddress(e.target.value)}
                ref={register({
                  required: "Please enter the address.",
                })}
                value={streetAddress}
                disabled={
                  (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                  schoolOrDistrictName.name.indexOf("not found") === -1
                }
              />
            </div>
          </div>
          <div id="school-row-id-4" className="row">
            <div className="column">
              <TextField
                id="custom-country-text-field"
                name="qrCountryField"
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                disabled={
                  (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                  schoolOrDistrictName.name.indexOf("not found") === -1
                }
              />
            </div>

            <div id="custom-city-column" className="column">
              <TextField
                id="custom-city-text-field"
                name="customCityTextField"
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                disabled={
                  (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                  schoolOrDistrictName.name.indexOf("not found") === -1
                }
              />
            </div>
          </div>
          {country === "United States" && OUTSIDE_US_OPTIONS.indexOf(state) === -1 ? (
            <div id="school-row-id-6" className="row">
              <div className="column">
                <Controller
                  control={control}
                  name="qrStateProvince"
                  defaultValue=""
                  rules={{
                    required: "Please select a state/province.",
                  }}
                  disabled={
                    isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1
                  }
                  render={(props) => (
                    <Select
                      id="custom-select-10"
                      label="State/Province"
                      {...props}
                      options={
                        US_STATES_CODES
                          
                      }
                      value={stateOrProvince}
                      disabled={
                        isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1 || locked
                      }
                      onChange={(e) => {
                        props.onChange(e);
                        handleStateOrProvinceChange(e);
                      }}
                      disableLeftAddon={false}
                      rightChildren={
                        !locked && <RiArrowDownSFill className="dropDownArrow" />
                      }
                    />
                  )}
                />
              </div>

              <div id="custom-postalCode-column" className="column">
                <TextField
                  id="postalCode-text-field"
                  name="postalCodeTextField"
                  label="Postal Code"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  // ref={register({ required })}
                  disabled={
                    (isSubmitting ||
                      OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                    schoolOrDistrictName.name.indexOf("not found") === -1
                  }
                />
              </div>
            </div>
          ) : (
            <div id="school-row-id-6" className="row">
              <div className="column">
                <TextField
                  id="custom-state-provice-text-field"
                  name="qrStateProvince"
                  label="State/Province"
                  value={stateOrProvince}
                  onChange={(e) => setStateOrProvince(e.target.value)}
                  disabled={
                    (isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                    schoolOrDistrictName.name.indexOf("not found") === -1
                  }
                />
              </div>

              <div id="custom-postalCode-column" className="column">
                <TextField
                  id="postalCode-text-field"
                  name="postalCodeTextField"
                  label="Postal Code"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  disabled={
                    (isSubmitting ||
                      OUTSIDE_US_OPTIONS.indexOf(state) === -1) &&
                    schoolOrDistrictName.name.indexOf("not found") === -1
                  }
                />
              </div>
            </div>)}

          {false && schoolOrDistrictName.name === "School not found?" ? (
            <div id="school-row-id-5" className="row">
              <TimezonePicker
                id="timezone-picker-id"
                name="timezonePickerId"
                absolute={true}
                placeholder="Select timezone..."
                value={timezone}
                defaultValue=""
                onChange={(e) => setTimezone(e)}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {(state !== "" && schoolOrDistrictName.name) ||
        OUTSIDE_US_OPTIONS.indexOf(state) >= 0 ? (
        <>
          <h2 className="get-quote-form-subHeader">Contact information</h2>
          <div id="name-row-id" className="row">
            <div className="column">
              <TextField
                id="contactName-text-field"
                name="contactNameTextField"
                label="Contact Name"
                ref={register({
                  required: "Please enter a contact name.",
                })}
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
              />
            </div>
            <div className="column">
              <TextField
                id="contact-email-text-field"
                name="contactEmailField"
                label="Contact Email"
                type="email"
                ref={register({
                  required: "Please enter a valid contact field.",
                })}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {localStorage.getItem("customer_service_token") && (
        <>
          <Checkbox
            id="show_billing_address"
            name="showBillingAddress"
            className="show_billing_address"
            label="Separate Billing Address?"
            value={showBillingAddress}
            checked={showBillingAddress}
            onChange={(e) => {
              setShowBillingAddress(e.target.checked);
            }}
          />
          {showBillingAddress && (
            <>
              <h2 className="get-quote-form-subHeader">Billing Address</h2>
              <div id="bill-school-row-id-2" className="row">
                <div id="bill-schoolName-column" className="schoolColumn">
                  <TextField
                    id="bill-schoolNametextfield"
                    name="bill-qrSchoolNametextfield"
                    label={isDistrict ? "District Name" : "School Name"}
                    onChange={(e) => setBillingAddress({
                      ...billingAddress,
                      name: e.target.value,
                    })}
                    value={billingAddress.name}
                    ref={register({
                      required:
                        "Please enter a " +
                        (isDistrict ? "district" : "school") +
                        " name.",
                    })}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div id="bill-school-row-id-7" className="row">
                <div id="bill-schoolAddress-column" className="streetAddressColumn">
                  <TextField
                    id="bill-streetAddressTextfield"
                    name="bill-qrStreetAddressTextfield"
                    label="Street Address"
                    onChange={(e) => setBillingAddress({
                      ...billingAddress,
                      street: e.target.value,
                    })}
                    ref={register({
                      required: "Please enter the address.",
                    })}
                    value={billingAddress.street}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div id="bill-school-row-id-7" className="row">
                <div id="bill-schoolAddress-column" className="streetAddressColumn">
                  <TextField
                    id="bill-streetAddressTextfield"
                    name="bill-qrStreetAddressTextfield"
                    label="Street Address Line 2"
                    onChange={(e) => setBillingAddress({
                      ...billingAddress,
                      streetLine2: e.target.value,
                    })}
                    ref={register()}
                    value={billingAddress.streetLine2}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div id="bill-school-row-id-4" className="row">
                <div className="column">
                  <TextField
                    id="bill-custom-country-text-field"
                    name="bill-qrCountryField"
                    label="Country"
                    value={billingAddress.country}
                    onChange={(e) => setBillingAddress({
                      ...billingAddress,
                      country: e.target.value,
                    })}
                    disabled={isSubmitting}
                  />
                </div>
                <div id="bill-custom-city-column" className="column">
                  <TextField
                    id="bill-custom-city-text-field"
                    name="bill-customCityTextField"
                    label="City"
                    value={billingAddress.city}
                    onChange={(e) => setBillingAddress({
                      ...billingAddress,
                      city: e.target.value,
                    })}
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              {billingAddress.country === "United States" ? (
                <div id="bill-school-row-id-6" className="row">
                  <div className="column">
                    <Controller
                      control={control}
                      name="bill-qrStateProvince"
                      defaultValue=""
                      rules={{
                        required: "Please select a state/province.",
                      }}
                      disabled={
                        isSubmitting
                      }
                      render={(props) => (
                        <Select
                          id="bill-custom-select-10"
                          label="State/Province"
                          {...props}
                          options={
                            US_STATES_CODES
                          }
                          value={billingAddress.state}
                          disabled={
                            isSubmitting
                          }
                          onChange={(e) => {
                            props.onChange(e);
                            handleBillingAddressStateOrProviceChange(e);
                          }}
                          disableLeftAddon={false}
                          rightChildren={
                            !locked && <RiArrowDownSFill className="dropDownArrow" />
                          }
                        />
                      )}
                    />
                  </div>
                  <div id="bill-custom-postalCode-column" className="column">
                    <TextField
                      id="bill-postalCode-text-field"
                      name="bill-postalCodeTextField"
                      label="Postal Code"
                      value={billingAddress.zip}
                      onChange={(e) => setBillingAddress({
                        ...billingAddress,
                        zip: e.target.value,
                      })}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              ) : (
                <div id="bill-school-row-id-6" className="row">
                  <div className="column">
                    <TextField
                      id="bill-custom-state-provice-text-field"
                      name="bill-qrStateProvince"
                      label="State/Province"
                      value={billingAddress.state}
                      onChange={(e) => setBillingAddress({
                        ...billingAddress,
                        state: e.target.value,
                      })}
                      disabled={isSubmitting}
                    />
                  </div>

                  <div id="bill-custom-postalCode-column" className="column">
                    <TextField
                      id="bill-postalCode-text-field"
                      name="bill-postalCodeTextField"
                      label="Postal Code"
                      value={billingAddress.zip}
                      onChange={(e) => setBillingAddress({
                        ...billingAddress,
                        zip: e.target.value,
                      })}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              )}
            </>
          )}

          <Checkbox
            id="send_orders"
            name="sendOrders"
            className="send_orders"
            label="Send to orders@deltamath.com"
            value={sendToOrders}
            checked={sendToOrders}
            onChange={(e) => {
              setSendToOrders(e.target.checked)
            }}
          />
          <Checkbox
            id="force_new_estimate"
            name="forceNewEstimate"
            className="force_new_estimate"
            label="Force new estimate"
            value={forceNewEstimate}
            onChange={(e) => {
              setForceNewEstimate(e.target.checked)
            }}
          />
        </>
      )}    
    </>
  );
}

export default SiteInfoForm;
