import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";

import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  US_STATES,
  US_STATES_CODES,
  OUTSIDE_US_OPTIONS,
} from "../../constants/states";

import { CountryRegionData } from "react-country-region-selector";
import { Dialog, DialogContent, DialogFooter } from "@react-md/dialog";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";
import { Card, CardHeader, CardTitle, CardContent } from "@react-md/card";
import { Select, TextField, Button, Radio } from "react-md";

import { RiArrowDownSFill } from "@react-icons/all-files/ri/RiArrowDownSFill";

// IMPORTING CONSTANTS FROM components/utilities
import SiteInfoForm from "./SiteInfoForm";
import { getDeltaMathAPI } from "../../utilities/utilities";
import { compact } from "lodash";

function QuoteForm(props) {
  const [quoteRequestSubmitted, setQuoteRequestSubmitted] =
    React.useState(false);
  const {
    control,
    errors,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm();
  const filterBy = () => true;

  const COUNTRIES = ["United States"];
  // FILLING ABOVE CONSTANTS WITH DATA AVAILABLE FROM react-country-region-selector PACKAGE
  CountryRegionData.forEach((data) => {
    if (data[0] !== "United States") {
      COUNTRIES.push(data[0]);
    }
  });

  const LICENSE_OPTION_MAP = {
    'school': 'School License',
    '6-12': 'District License (Grades 6-12)',
    '9-12': 'District License (Grades 9-12)',
    '6-8': 'District License (Grades 6-8)',
    '7-12': 'District License (Grades 7-12)',
    '7-8': 'District License (Grades 7-8)',
  }

  // const YEAR_PRORATED = "23-24 Prorated";
  const typeaheadRef = React.createRef();

  const [autocompleteValue] = useState("hey");
  const [schoolYear, setSchoolYear] = React.useState();
  const [toggleState, setToggleState] = React.useState("school_district");
  const [individualSiteType, setIndividualSiteType] = React.useState("");
  const [licenseCount, setLicenseCount] = React.useState(undefined);
  const [licenseTier, setLicenseTier] = React.useState("Integral");
  const [license, setLicense] = React.useState("");

  const [state, setState] = React.useState("");
  const [schoolOrDistrictName, setSchoolOrDistrictName] = React.useState({
    id: "",
    name: "",
    address: {
      street: "",
    },
  });
  const [school, setSchool] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [streetAddress, setStreetAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [timezone, setTimezone] = React.useState("");
  const [stateOrProvince, setStateOrProvince] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [contactName, setContactName] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [sendToOrders, setSendToOrders] = React.useState(false);
  const [forceNewEstimate, setForceNewEstimate] = React.useState(false);
  const [rosteringSystem, setRosteringSystem] = React.useState(null);
  const [learningManagementSystem, setLearningManagementSystem] =
    React.useState(null);
  const [dontClearAddress, setDontClearAddress] = React.useState(false);
  const [refetchSchoolInfo, setRefetchSchoolInfo] = React.useState(true);
  const [locked, setLocked] = React.useState(false);

  const [submitInitiated, setSubmitInitiated] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [priceSchemeOptions, setPriceSchemeOptions] = useState([]);
  const [siteType, setSiteType] = useState();
  const [licenseOptions, setLicenseOptions] = useState();
  const [billingAddress, setBillingAddress] = useState();
  const [showBillingAddress, setShowBillingAddress] = useState(false);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      localStorage.getItem("customer_service_token")
    ) {
      setSendToOrders(!!localStorage.getItem("customer_service_token"));
    }

    if (typeof window !== "undefined") {
      const savedAddress = localStorage.getItem("billingAddress");
      if (savedAddress) {
        setBillingAddress(JSON.parse(savedAddress));
      } else {
        setBillingAddress({
          name: "",
          street: "",
          streetLine2: "",
          country: "United States",
          city: "",
          state: "",
          zip: "",
        });
      }
    }
  }, []);

  const isDistrict = () => siteType === 'District' || individualSiteType === "District";

  const handleSchoolYearChange = React.useCallback((nextValue, _option) => {
    setSchoolYear(nextValue);
  }, []);

  const handleStateChange = React.useCallback((nextValue, _option) => {
    if (nextValue === "Outside of US" || OUTSIDE_US_OPTIONS.includes(nextValue)) {
      setSchoolOrDistrictName({
        ...schoolOrDistrictName,
        name: "not found",
      })
    } else {
      setSchoolOrDistrictName({ name: "" });
      setLicenseOptions();
      setLicense("");
    }
    setState(nextValue);
  }, []);

  const handleCountryChange = React.useCallback((nextValue, _option) => {
    setCountry(nextValue);
  }, []);

  // const handleCheckboxChange = (e) => {
  //   setRequestIntegration(e.target.checked);
  // }

  const handleRosteringSystemChange = React.useCallback(
    (nextValue, _option) => {
      if (nextValue === rosteringSystem) setRosteringSystem(null);
      else setRosteringSystem(nextValue);
    }
  );

  const handleLMSChange = React.useCallback((nextValue, _option) => {
    if (nextValue === learningManagementSystem)
      setLearningManagementSystem(null);
    else setLearningManagementSystem(nextValue);
  });

  const handleStateOrProvinceChange = React.useCallback(
    (nextValue, _option) => {
      setStateOrProvince(nextValue);
    },
    []
  );

  const handleBillingAddressStateOrProviceChange = React.useCallback(
    (nextValue, _option) => {
      setBillingAddress({ ...billingAddress, state: nextValue });
    },
    [billingAddress]
  );

  const changeSiteType = React.useCallback(
    (nextValue, _option) => {
      if (typeaheadRef && typeaheadRef.current) {
        typeaheadRef.current.clear();
      }
      setSiteType(nextValue);
      setSchoolOrDistrictName({ name: "" });
      setLicenseOptions();
      setLicense("");
    },
    [setSiteType, setSchoolOrDistrictName, typeaheadRef]
  );

  const fetchPriceSchemeOptions = React.useCallback(async () => {
    try {
      const customer_service_token = localStorage.getItem("customer_service_token");
      const headers = customer_service_token ? {
        'customer_service_token': customer_service_token
      } : {}

      const res = await fetch(getDeltaMathAPI() + '/public/quote/price-scheme/published', {
        headers: headers
      });
      const data = await res.json();

      setPriceSchemeOptions(
        data
          .sort(opt => (opt.default ? -1 : 1))
          .map(opt => ({
            value: opt.key,
            label: opt.key,
            default: opt.default,
          }))
      );
      setSchoolYear((data.find(opt => opt.default) || data[0]).key);
    } catch (err) {
      setError('priceSchemeError', {
        type: 'custom',
        message: 'Unable to load pricing options, please try again later.',
      });
    }
  }, [setError, setPriceSchemeOptions, setSchoolYear]);

  const handleLicenseChange = React.useCallback((nextValue, _option) => {
    if (
      license &&
      license.indexOf("School") !== nextValue.indexOf("School") &&
      (!props.nces || props.nces.length === 7)
    ) {
      setSchoolOrDistrictName({ name: "" });
      if (typeaheadRef && typeaheadRef.current) typeaheadRef.current.clear();
    }
    setLicense(nextValue);
  });

  const handleAutocompleteOnSelect = (item) => {
    setSchoolOrDistrictName(item);
  };

  const handleSchoolDistrictSelect = () => {
    setToggleState("school_district");
    if (props.lms && props.lms.toLowerCase() === "none") {
      setLearningManagementSystem("");
      setRosteringSystem("");
    } else {
      setLearningManagementSystem(null);
      setRosteringSystem(null);
    }
    setIndividualSiteType("");
    setSiteType();
    setSchoolOrDistrictName({ name: "" });
    setLicense("");
    setState("");
  };
  const handleIndividualSelect = () => {
    setLicense("Individual License");
    if (props.lms && props.lms.toLowerCase() === "none") {
      setLearningManagementSystem("");
      setRosteringSystem("");
    } else {
      setLearningManagementSystem(null);
      setRosteringSystem(null);
    }
    setToggleState("individual");
    setState("");
    setSiteType();
    setSchoolOrDistrictName({ name: "" });
    setLicenseOptions();
  };

  const getStateCode = () => {
    var i;
    US_STATES.forEach((currentState, index) => {
      if (currentState === state) {
        i = index - 1;
      }
    });
    return US_STATES_CODES[i];
  };

  React.useEffect(() => {
    if (schoolOrDistrictName.name !== "") {
      if (schoolOrDistrictName.name.indexOf("not found") === -1) {
        setSchool(schoolOrDistrictName.name);
        setValue("qrSchoolNametextfield", schoolOrDistrictName.name);
        // let brokenAddress = schoolOrDistrictName.schoolAddress.split(",");
        setStreetAddress(schoolOrDistrictName.address.street);
        setValue(
          "qrStreetAddressTextfield",
          schoolOrDistrictName.address.street
        );
        setCity(schoolOrDistrictName.address.city);
        if (schoolOrDistrictName.address) {
          setCountry("United States");
          setValue("qrCountryField", "United States");
          setValue("countrySelectField", "United States");
        } else {
          // can this be set other than selecting autocomplete?
          setValue("qrCountryField", "");
          setValue("countrySelectField", "");
        }
        setStateOrProvince(getStateCode());
        setValue("qrStateProvince", getStateCode());
        setValue("stateProvinceField", getStateCode());
        setPostalCode(schoolOrDistrictName.address.zip);
      } else if (schoolOrDistrictName.name.indexOf("not found") > -1) {
        setSchool("");
        setValue("qrSchoolNametextfield", "");
        setStreetAddress("");
        setValue("qrStreetAddressTextfield", "");
        setCity("");
        setCountry("United States");
        setValue("qrCountryField", "United States");
        setValue("countrySelectField", "United States");
        setStateOrProvince(getStateCode());
        setValue("qrStateProvince", getStateCode());
        setValue("stateProvinceField", getStateCode());
        setPostalCode("");
      }

      if (toggleState !== 'individual') {
        if (getStateCode()) {
          fetch(getDeltaMathAPI() + `/public/quote/license-options?ncesId=${schoolOrDistrictName.id}&state=${getStateCode()}&type=${isDistrict() ? "district" : "school"}`)
            .then((response) => response.json())
            .then((data) => {
              setLicenseOptions(data.licenseOptions.map(opt => LICENSE_OPTION_MAP[opt]));
              setLicense(LICENSE_OPTION_MAP[data.licenseOptions[0]]);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else { // If we don't have a state, we're outside of US or AE, AP
          const licenseOptions = ["school"]
          setSiteType("School");
          setLicenseOptions(licenseOptions.map(opt => LICENSE_OPTION_MAP[opt]));
          setLicense(LICENSE_OPTION_MAP[licenseOptions[0]]);
        }
      } else {
        setLicense('Individual License');
        if (!getStateCode()) {
          setIndividualSiteType("School");
        }
      }
    }
  }, [schoolOrDistrictName]);

  React.useEffect(() => {
    if (!props.nces || !dontClearAddress) {
      setSchool("");
      setStreetAddress("");
      setCity("");
      setCountry(state === "Outside of US" ? "" : "United States");
      setStateOrProvince(getStateCode());
      setPostalCode("");
    } else {
      // Clear flag and make sure other spots are set
      setDontClearAddress(false);
      // If we have the dont clear address flag set and we get here that means we have
      // set a state so we need to make sure we are setting the proper other values
      setStateOrProvince(getStateCode());
      setValue("qrStateProvince", getStateCode());
      setValue("stateProvinceField", getStateCode());
    }
  }, [state]);

  // HANDLING GET A QUOTE FORM SUBMISSION
  const onQuoteSubmit = async (e) => {
    e.preventDefault();
    clearErrors("submit");
    setSubmitInitiated(false);
    if (
      !schoolOrDistrictName.name &&
      OUTSIDE_US_OPTIONS.indexOf(state) === -1
    ) {
      setError("submit", {
        type: "manual",
        message: "You must fill out all of the required information.",
      });
      return;
    }

    if (
      !contactEmail.length ||
      !contactName.length ||
      !license ||
      (!licenseCount && toggleState === "individual") ||
      !schoolOrDistrictName.name ||
      !(streetAddress || schoolOrDistrictName?.address?.street)
    ) {
      setError("submit", {
        type: "manual",
        message: "You must fill out all of the required information.",
      });
      return;
    }

    try {
      //* send server call from here on form submission
      const id = schoolOrDistrictName ? schoolOrDistrictName.id : null;
      const name =
        schoolOrDistrictName &&
          schoolOrDistrictName.name !== "not found" &&
          schoolOrDistrictName.name !== "School not found?" &&
          schoolOrDistrictName.name !== "District not found?" &&
          schoolOrDistrictName.name !== ""
          ? schoolOrDistrictName.name
          : school;
      const address =
        schoolOrDistrictName && schoolOrDistrictName?.address?.street
          ? schoolOrDistrictName.address.street
          : streetAddress;

      const body = {
        type: license,
        id: id,
        name: name,
        address: address,
        city,
        state: OUTSIDE_US_OPTIONS.includes(state) ? stateOrProvince : getStateCode(),
        zip: postalCode,
        country,
        contactName,
        contactEmail,
        licenseCount,
        licenseTier,
        individualSiteType,
        rosteringSystem,
        learningManagementSystem,
        schoolYear,
        sendToOrders,
        forceNewEstimate,
      };

      if (
        typeof window !== "undefined" &&
        localStorage.getItem("customer_service_token")
      ) {
        // window type check needed because in build process there is no window defined
        body.customer_service_token = localStorage.getItem(
          "customer_service_token"
        ); // see https://github.com/gatsbyjs/gatsby/issues/14480 for more information
      }

      if (billingAddress && showBillingAddress) {
        body.billingAddress = billingAddress;
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };
      setSubmitInitiated(true);

      // This sucks but it's better than redoing all the license backend code
      if (licenseTier === "Both Plus and Integral (Compare)") {
        body.licenseTier = "Integral";
        requestOptions.body = JSON.stringify(body);
        fetch(getDeltaMathAPI() + "/quote_request", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setSubmitInitiated(false);
            if (data.success) {
              setQuoteRequestSubmitted(true);
              setSuccessMessage(data?.message);
              // reset();
              setSendToOrders(!!localStorage.getItem("customer_service_token"));
              setForceNewEstimate(false);
            } else if (data.message) {
              setError("submit", {
                type: "manual",
                message: data.message,
              });
            }
          });

        body.licenseTier = "Plus";
        requestOptions.body = JSON.stringify(body);
        fetch(getDeltaMathAPI() + "/quote_request", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setSubmitInitiated(false);
            if (data.success) {
              setQuoteRequestSubmitted(true);
              setSuccessMessage(data?.message);
              reset();
              setSendToOrders(!!localStorage.getItem("customer_service_token"));
              setForceNewEstimate(false);
            } else if (data.message) {
              setError("submit", {
                type: "manual",
                message: data.message,
              });
            }
          });
      } else {
        fetch(getDeltaMathAPI() + "/quote_request", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setSubmitInitiated(false);
            if (data.success) {
              setQuoteRequestSubmitted(true);
              if (data?.message) {
                setSuccessMessage(data?.message);
              }
              reset();
              setSendToOrders(!!localStorage.getItem("customer_service_token"));
              setForceNewEstimate(false);
            } else if (data.message) {
              setError("submit", {
                type: "manual",
                message: data.message,
              });
            }
          });
      }
    } catch (error) {
      // handle server errors
      setSubmitInitiated(false);
      setError("submit", {
        type: "manual",
        message: "Your request couldn't be sent. Please try again.",
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("billingAddress", JSON.stringify(billingAddress));
  }, [billingAddress])

  useEffect(() => {
    if (!!!props.nces) {
      setLocked(false);
    }
  }, [props.nces]);

  useEffect(() => {
    fetchPriceSchemeOptions();
  }, [fetchPriceSchemeOptions]);

  useEffect(() => {
    if (
      !!props.quoteType &&
      toggleState &&
      !!props.nces &&
      (license || individualSiteType)
    ) {
      setRefetchSchoolInfo(true);
    }
  }, [props.quoteType, props.nces, toggleState, individualSiteType, license]);

  // If we have an NCES id from our query params we want to fetch the school or district's
  // data so we can pre-populate data for the user
  useEffect(() => {
    if (!!props.nces && license && refetchSchoolInfo) {
      try {
        setRefetchSchoolInfo(false);
        setDontClearAddress(true);

        const ncesIdToUse =
          license.includes("District") || individualSiteType === "District"
            ? props.nces.slice(0, 7)
            : props.nces;

        if (
          (license.includes("School") || individualSiteType === "School") &&
          props.nces.length === 7
        ) {
          setDontClearAddress(false);
          setLocked(false);
          setState("");
          return;
        }

        fetch(getDeltaMathAPI() + `/nces_data?nces=${ncesIdToUse}`, {
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data) => {
            setState(data.state ?? "");
            setSchoolOrDistrictName({
              name: data.name ?? "",
              id: ncesIdToUse,
              address: {
                street: data.street ?? "",
              },
            });
            setStreetAddress(data.street ?? "");
            setCity(data.city ?? "");
            setPostalCode(data.zip ?? "");
            setLocked(true);
          });
      } catch (err) {
        setDontClearAddress(false);
        setLocked(false);
        console.log("ERROR", err);
      }
    }
  }, [props.nces, refetchSchoolInfo, license]);

  // if we have these query params we want to make sure to set them
  useEffect(() => {
    if (props.license) {
      setLicenseTier(
        props.license === "Plus" && props.noCompare ? "Plus" :
          (props.license !== "Integral"
            ? "Both Plus and Integral (Compare)"
            : props.license ?? "Integral")
      );
    }
    if (
      (props.licenseTier && props.licenseTier === "School License") ||
      props.licenseTier === "District License (Grades 6-12)"
    ) {
      setLicense(props.licenseTier);
    }
    if (props.email) {
      setContactEmail(props.email);
    }
    if (props.name) {
      setContactName(props.name);
    }

    // Set these if available
    if (props.lms && props.lms.toLowerCase() !== "none") {
      if (props.lms === "ClassLink/OneRoster" || props.lms === "Clever") {
        handleRosteringSystemChange(props.lms);
      } else {
        handleLMSChange(props.lms);
      }
    } else {
      // you set both of these to select the none radio
      handleLMSChange("");
      handleRosteringSystemChange("");
    }
    if (props.quoteType) {
      if (props.quoteType === "site") {
        setToggleState("school_district");
        setIndividualSiteType("");
      } else if (props.quoteType === "individual") {
        setLicense("Individual License");
        setToggleState("individual");
        if (!!props.nces && props.nces.length === 7) {
          handleIndividualSiteTypeChange("District");
        } else if (!!props.nces) {
          handleIndividualSiteTypeChange("School");
        }
      }
    }
  }, [
    props.license,
    props.licenseTier,
    props.email,
    props.name,
    props.lms,
    props.quoteType,
    props.nces,
  ]);

  const showSubmitError = (msg) => {
    return (
      <div className="validation-alert msg-error">
        <p>{msg.message}</p>
      </div>
    );
  };

  const handleLicenseCountChange = (event) => {
    const num = parseInt(event.target.value);
    setLicenseCount(num);
  };

  const handleLicenseTierChange = (tier) => {
    if (tier !== "Integral") {
      setLearningManagementSystem(null);
      setRosteringSystem(null);
    }
    setLicenseTier(tier);
  };

  const handleIndividualSiteTypeChange = React.useCallback(
    (nextValue, _option) => {
      if (individualSiteType && individualSiteType !== nextValue) {
        setSchoolOrDistrictName({ name: "" });
        if (typeaheadRef && typeaheadRef.current) typeaheadRef.current.clear();
      }
      setIndividualSiteType(nextValue);
      setSiteType(nextValue.toLowerCase());
    }
  );

  const showQuoteSuccess = (
    <div className=" msg-confirm">
      <h2 className="create-teacher-account-form-subHeader">Success</h2>
      <p>We have received your quote request.</p>
      <br />
      {successMessage && <p>{successMessage}</p>}
      <Button
        className="form-submit-btn about__section-2-info-button outline-primary-btn"
        onClick={() => {
          setQuoteRequestSubmitted(false);
          props.disableQuote();
        }}
        theme="primary"
        themeType="outline"
      >
        Okay
      </Button>
    </div>
  );

  React.useEffect(() => {
    if (props.visibleQuote === false) {
      resetStates();
      clearErrors();
    }
  }, [props.visibleQuote]);

  const resetStates = () => {
    setSiteType();
    setLicense("");
    setState("");
    setSchoolOrDistrictName({ name: "" });
    setSchool("");
    setCountry("");
    setStreetAddress("");
    setCity("");
    setTimezone("");
    setStateOrProvince("");
    setPostalCode("");
    setContactName("");
    setContactEmail("");
    setLicenseCount(1);
    setLicenseTier("Integral");
    setToggleState("school_district");
    setIndividualSiteType("");
    setRosteringSystem(null);
    setLearningManagementSystem(null);
  };

  const clearGlobalError = () => {
    clearErrors("submit");
  };

  const [isLoading, setIsLoading] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const handleSearch = (query) => {
    setIsLoading(true);

    fetch(
      getDeltaMathAPI() +
      `/digger_autocomplete?type=${isDistrict() ? "districts" : "schools"
      }&state=${stateOrProvince}&search=${query}`
    )
      .then((resp) => resp.json())
      .then((items) => {
        let options = [];

        for (let i = 0; i < items.length; i++) {
          const theItem = items[i];
          const match = items.find(
            (i) => i.name === theItem.name && i.id !== theItem.id
          );
          options.push({
            id: theItem.id,
            name:
              theItem.name +
              (typeof match !== "undefined"
                ? " (" + theItem.address.city + ")"
                : ""),
            address: theItem.address,
          });
        }

        options.unshift({
          id: -1,
          name: (isDistrict() ? "District" : "School") + " not found?",
          address: { street: "" },
        });

        setSchoolOptions(options);
        setIsLoading(false);
      });
  };

  const disableLicenseTierSelect =
    !!props.licenseTier &&
    (props.licenseTier === "District License (Grades 6-12)" ||
      props.licenseTier === "School License") &&
    !!!props.quoteType;

  const licenseSelectForm = (
    <>
      {toggleState !== 'individual' && (
        <div className='row'>
          <div id='site-type-label' className='column'>
            Is this quote for a school or a district?
          </div>
          <div id='site-type-picker' className='column'>
            <Radio
              id='school-checkbox'
              value='School'
              name='School'
              checked={siteType === 'School'}
              onChange={(e) => changeSiteType('School')}
              label='School'
            />
            <Radio
              id='district-checkbox'
              value='District'
              name='District'
              checked={siteType === 'District'}
              onChange={(e) => changeSiteType('District')}
              label='District'
              disabled={schoolOrDistrictName.name === "not found" && !stateOrProvince}
            />
          </div>
        </div>
      )}

      {siteType && (
        <>
          <h2 className="get-quote-form-subHeader">
            {isDistrict() ? "District" : "School"} information
          </h2>
          <div id="school-row-id-1" className="row">
            {!locked && <div className="column">
              <Controller
                control={control}
                name="qrStateField"
                defaultValue=""
                rules={{
                  required: "Please select a state.",
                }}
                disabled={isSubmitting}
                render={(props) => (
                  <Select
                    id="custom-select-8"
                    label="State"
                    {...props}
                    options={US_STATES}
                    value={state}
                    onChange={(e) => {
                      props.onChange(e);
                      handleStateChange(e);
                    }}
                    disableLeftAddon={false}
                    rightChildren={<RiArrowDownSFill className="dropDownArrow" />}
                  />
                )}
              />
            </div>}
            {OUTSIDE_US_OPTIONS.indexOf(state) === -1 && state !== "" && !locked ? (
              <>
                <div id="schoolOrDistrictName-column" className="column">
                  <AsyncTypeahead
                    id="qrSchoolOrDistrictName-text-field"
                    name="qrSchoolOrDistrictName-text-field"
                    labelKey="name"
                    ref={typeaheadRef}
                    inputProps={{
                      name: "autocomplete-school-or-district",
                      value: autocompleteValue,
                    }}
                    minLength={4}
                    isLoading={isLoading && false}
                    onChange={(text, e) => {
                      if (text.length > 0) handleAutocompleteOnSelect(text[0]);
                    }}
                    filterBy={filterBy}
                    onSearch={handleSearch}
                    options={schoolOptions}
                    placeholder={
                      isDistrict()
                        ? "Search district here..."
                        : "Search school here..."
                    }
                  />

                  {school === "" && false ? (
                    <p style={{ padding: "5px", fontSize: "0.9rem" }}>
                      Can't find your {isDistrict() ? "district" : "school"}? Select{" "}
                      <i>"{isDistrict() ? "District" : "School"} not found?</i>"
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {licenseOptions && !disableLicenseTierSelect && (
            <label htmlFor="custom-select-7" className="labelText">
              Select from the following (only one):
            </label>
          )}
          {licenseOptions && toggleState !== 'individual' && (

            <div id="license-row-id" className="row">
              <div className="licenseColumn">
                <Controller
                  control={control}
                  name="qrLicense"
                  defaultValue=""
                  rules={{
                    required: "Please choose a license.",
                  }}
                  disabled={isSubmitting || disableLicenseTierSelect}
                  render={(props) => (
                    <Select
                      id="custom-select-7"
                      label="License"
                      {...props}
                      options={licenseOptions}
                      value={license}
                      disabled={disableLicenseTierSelect}
                      onChange={(e) => {
                        props.onChange(e);
                        handleLicenseChange(e);
                      }}
                      disableLeftAddon={false}
                      // Remove the drop down arrow if we are defaulting the license and not allowing it to update
                      rightChildren={
                        !disableLicenseTierSelect && (
                          <RiArrowDownSFill className="dropDownArrow" />
                        )
                      }
                    />
                  )}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );

  // if we specified the NCES in query params that means we are launching from an email for people to get a new quote
  // If they are Integral we select it by default then don't let them change to a lower tier
  // If we specified NCES id then we want to allow them to get a quote for both Integral and Plus
  const licenseTierOptions = compact([
    "Integral",
    "Plus",
    !!props.nces ? "Both Plus and Integral (Compare)" : null,
  ]);
  const disableLicenseSelect = props.license === "Integral";
  const licenseTierForm = (
    <>
      <div id="school-row-id-1" className="row individual-row">
        <div className="column">
          <Controller
            control={control}
            name="qrLicenseTier"
            defaultValue=""
            rules={{
              required: "Please select a license type",
            }}
            disabled={isSubmitting || disableLicenseSelect}
            render={(props) => (
              <Select
                id="custom-select-8"
                label="License type"
                {...props}
                options={licenseTierOptions}
                value={licenseTier}
                onChange={(e) => {
                  handleLicenseTierChange(e);
                }}
                disableLeftAddon={false}
                disabled={disableLicenseSelect}
                rightChildren={
                  !disableLicenseSelect && (
                    <RiArrowDownSFill className="dropDownArrow" />
                  )
                }
              />
            )}
          />
        </div>
        {toggleState === "individual" ? (
          <>
            <div className="column">
              {props.licenseCount && (
                <p className="licenseMessage">
                  You purchased {props.licenseCount}{" "}
                  {props.licenseCount > 1 ? "licenses" : "license"} last year.
                </p>
              )}
              <TextField
                id="custom-select-8"
                label={licenseCount ? undefined : "License count"}
                labelStyle={{
                  pointerEvents: "none",
                }}
                type="number"
                // defaultValue="1"
                min="1"
                value={licenseCount}
                onChange={(e) => {
                  if (e.target.value <= 0) e.target.value = 1;
                  handleLicenseCountChange(e);
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {!disableLicenseSelect && (
          <div className="column">
            <p className="learn-plan">
              <a
                href="https://files.deltamath.com/DeltaMath-Flyer.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>{" "}
              to learn
              <br />
              which plan is right for you.
            </p>
          </div>
        )}
      </div>
    </>
  );

  const renderLmsForm = (
    <>
      <label htmlFor="custom-select-22" className="labelText">
        Rostering or Learning Management System (choose one if appropriate):
      </label>
      <div className="row">
        <div className="column">
          <Radio
            id="ClassLink-checkbox"
            value="ClassLink/OneRoster"
            name="ClassLink/OneRoster"
            checked={rosteringSystem === "ClassLink/OneRoster"}
            onChange={(e) => {
              handleLMSChange("");
              handleRosteringSystemChange("ClassLink/OneRoster");
            }}
            label="ClassLink/OneRoster"
          />
          <Radio
            id="Clever-checkbox"
            value="Clever"
            name="Clever"
            checked={rosteringSystem === "Clever"}
            onChange={(e) => {
              handleLMSChange("");
              handleRosteringSystemChange("Clever");
            }}
            label="Clever"
          />
        </div>
        <div className="column">
          <Radio
            id="Canvas-checkbox"
            value="Canvas"
            name="Canvas"
            checked={learningManagementSystem === "Canvas"}
            onChange={(e) => {
              handleLMSChange("Canvas");
              handleRosteringSystemChange("");
            }}
            label="Canvas"
          />
          <Radio
            id="Schoology-checkbox"
            value="Schoology"
            name="Schoology"
            checked={learningManagementSystem === "Schoology"}
            onChange={(e) => {
              handleLMSChange("Schoology");
              handleRosteringSystemChange("");
            }}
            label="Schoology"
          />
        </div>
        <div className="column">
          <Radio
            id="none-checkbox"
            value="None"
            name="None"
            checked={rosteringSystem === "" && learningManagementSystem === ""}
            onChange={(e) => {
              handleLMSChange("");
              handleRosteringSystemChange("");
            }}
            label="None"
          />
        </div>
      </div>
    </>
  );

  // If the NCES id is provided that means it is not an individual license so we want to hide this option
  const ncesIdProvided = !!props.nces;
  const siteTypeProvided = !!props.quoteType;
  const render22_23Form = (
    <>
      {(!ncesIdProvided || siteTypeProvided) && (
        <div id="license-row-id" className="row">
          <div className="licenseColumn">
            <div className="individual-site-toggle-container">
              <Button
                className={
                  "account-type-toggle-button " +
                  (toggleState !== "individual" ? "selected" : "")
                }
                theme="primary"
                themeType="outline"
                onClick={handleSchoolDistrictSelect}
              >
                School/District Site
              </Button>
              <Button
                className={
                  "account-type-toggle-button " +
                  (toggleState === "individual" ? "selected" : "")
                }
                theme="primary"
                themeType="outline"
                onClick={handleIndividualSelect}
              >
                Individual Licenses
              </Button>
            </div>
          </div>
        </div>
      )}
      {toggleState === "individual" ? (
        <>
          {licenseTierForm}
          <div className="row plus-warning-row">
            <em>Individual licenses do not include admin portal access.</em>
          </div>
          <div className="row">
            <div id="site-type-label" className="column">
              Is this quote for a school or a district?
            </div>
            <div id="site-type-picker" className="column">
              <Radio
                id="school-checkbox"
                value="School"
                name="School"
                checked={individualSiteType === "School"}
                onChange={(e) => handleIndividualSiteTypeChange("School")}
                label="School"
              />
              <Radio
                id="district-checkbox"
                value="District"
                name="District"
                checked={individualSiteType === "District"}
                onChange={(e) => handleIndividualSiteTypeChange("District")}
                label="District"
                disabled={schoolOrDistrictName.name === "not found" && !stateOrProvince}
              />
            </div>
          </div>
          {licenseSelectForm}
        </>
      ) : (
        <>
          {licenseSelectForm}
          {license !== "" && license !== "Individual License" ? (
            <>
              {licenseTierForm}
              {licenseTier !== "Plus" ? <>{renderLmsForm}</> : <></>}
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {(license !== "" && license !== "Individual License") ||
        individualSiteType !== "" ? (
        <>
          <SiteInfoForm
            isDistrict={isDistrict()}
            handleStateChange={handleStateChange}
            handleAutocompleteOnSelect={handleAutocompleteOnSelect}
            handleSearch={handleSearch}
            setSchool={setSchool}
            handleCountryChange={handleCountryChange}
            setCountry={setCountry}
            handleStateOrProvinceChange={handleStateOrProvinceChange}
            setStateOrProvince={setStateOrProvince}
            setTimezone={setTimezone}
            setContactName={setContactName}
            setContactEmail={setContactEmail}
            setStreetAddress={setStreetAddress}
            isSubmitting={isSubmitting}
            streetAddress={streetAddress}
            schoolOrDistrictName={schoolOrDistrictName}
            country={country}
            city={city}
            state={state}
            school={school}
            typeaheadRef={typeaheadRef}
            isLoading={isLoading}
            schoolOptions={schoolOptions}
            stateOrProvince={stateOrProvince}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            timezone={timezone}
            setCity={setCity}
            contactName={contactName}
            contactEmail={contactEmail}
            locked={locked}
            sendToOrders={sendToOrders}
            setSendToOrders={setSendToOrders}
            forceNewEstimate={forceNewEstimate}
            setForceNewEstimate={setForceNewEstimate}
            billingAddress={billingAddress}
            setBillingAddress={setBillingAddress}
            showBillingAddress={showBillingAddress}
            setShowBillingAddress={setShowBillingAddress}
            handleBillingAddressStateOrProviceChange={handleBillingAddressStateOrProviceChange}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <Dialog
      id="quote-form-dialog"
      visible={props.visibleQuote}
      onRequestClose={props.disableQuote}
      aria-labelledby="dialog-title"
      className="quoteFormDialog form-dialog"
    >
      <span id="dialog-title" className="sr-only">
        Quote form dialog
      </span>
      <DialogFooter>
        <VscChromeClose
          className="dialog-close1"
          id="dialog-close"
          onClick={props.disableQuote}
        />
      </DialogFooter>
      <DialogContent className="quote-form-content">
        <Card
          className="modalTeamCard"
          id="quote-form-dialog-toggle"
          onClick={props.enableQuote}
        >
          <CardHeader className="teamCard-text">
            <CardTitle className="teamCard-text-name">Get a Quote</CardTitle>
          </CardHeader>
          <CardContent>
            {quoteRequestSubmitted ? (
              showQuoteSuccess
            ) : (
              <form
                className="popup-form get-quote-form container"
                onSubmit={onQuoteSubmit}
              >
                {!!props.nces === false && (
                  <>
                    <label htmlFor="custom-select-11" className="labelText">
                      Select the school year for which you need a quote:
                    </label>
                    <div id="school-year-row-id" className="row">
                      <div className="schoolYearColumn">
                        <Controller
                          control={control}
                          name="qrSchoolYear"
                          defaultValue=""
                          rules={{
                            required: "Please choose a school year.",
                          }}
                          disabled={isSubmitting}
                          render={(props) => (
                            <Select
                              id="custom-select-11"
                              label="School Year"
                              {...props}
                              options={priceSchemeOptions}
                              value={schoolYear}
                              onChange={(e) => {
                                props.onChange(e);
                                handleSchoolYearChange(e);
                              }}
                              disableLeftAddon={false}
                              rightChildren={
                                <RiArrowDownSFill className="dropDownArrow" />
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}
                {render22_23Form}
                <div className="row error-row">
                  {errors.qrSchoolYear && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.qrSchoolYear.message}</p>
                    </div>
                  )}{" "}
                  {errors.qrLicense && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.qrLicense.message}</p>
                    </div>
                  )}{" "}
                  {errors.qrStateField && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.qrStateField.message}</p>
                    </div>
                  )}
                  {errors.qrSchoolOrDistrictNameTextField && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.qrSchoolOrDistrictNameTextField.message}</p>
                    </div>
                  )}
                  {errors.qrSchoolNametextfield && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.qrSchoolNametextfield.message}</p>
                    </div>
                  )}
                  {errors.qrStreetAddressTextfield && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.qrStreetAddressTextfield.message}</p>
                    </div>
                  )}
                  {errors.qrCountryField && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.qrCountryField.message}</p>
                    </div>
                  )}
                  {errors.qrStateProvince && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.qrStateProvince.message}</p>
                    </div>
                  )}
                  {errors.contactNameTextField && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.contactNameTextField.message}</p>
                    </div>
                  )}
                  {errors.contactEmailField && (
                    <div className="validation-alert msg-error ">
                      <p>{errors.contactEmailField.message}</p>
                    </div>
                  )}
                  {errors && errors.submit && showSubmitError(errors.submit)}
                </div>
                <div className="row">
                  <Button
                    className="form-submit-btn"
                    disabled={submitInitiated}
                    onClick={clearGlobalError}
                    themeType="contained"
                    type="submit"
                  >
                    {submitInitiated ? "submitting..." : "Submit"}
                  </Button>
                </div>
              </form>
            )}
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
}

export default QuoteForm;
