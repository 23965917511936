import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { MediaContainer } from "react-md";
import VideoPopup from "./VideoPopup";

function FeaturesContainer(props) {

  const shouldVideoBeOpened = (target) => (props.searchParams && props.searchParams.get('play') && window.location.href.split('#')[1] === target) ? true : false;

  const data = useStaticQuery(
    graphql`
      query {
        desktop1: file(relativePath: { eq: "images/features/create_your_own_problem.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop2: file(relativePath: { eq: "images/features/upload_student_work.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop3: file(relativePath: { eq: "images/features/standard_maps.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop4: file(relativePath: { eq: "images/features/print.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop5: file(relativePath: { eq: "images/features/integrations.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop5a: file(relativePath: { eq: "images/features/videos.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop6: file(relativePath: { eq: "images/features/create_assignment.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop7: file(
          relativePath: { eq: "images/features/tests.png" }
        ) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop8: file(relativePath: { eq: "images/features/automated_test_corrections.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop9: file(relativePath: { eq: "images/features/problem_types.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop10: file(relativePath: { eq: "images/features/usage_reports.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop11: file(relativePath: { eq: "images/features/student_performance_reports.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop12: file(relativePath: { eq: "images/features/pushed_assignments.png" }) {
          childImageSharp {
            fixed(width: 440) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );
  const create_your_own_problem_image = data.desktop1.childImageSharp.fixed;
  const upload_student_work_image = data.desktop2.childImageSharp.fixed;
  const standard_maps_image = data.desktop3.childImageSharp.fixed;
  const print_image = data.desktop4.childImageSharp.fixed;
  const integrations_image = data.desktop5.childImageSharp.fixed;
  const videos_image = data.desktop5a.childImageSharp.fixed;
  const create_assignment_image = data.desktop6.childImageSharp.fixed;
  // const tests_image = data.desktop7.childImageSharp.fixed;
  const automated_test_corrections_image = data.desktop8.childImageSharp.fixed;
  const problem_types_image = data.desktop9.childImageSharp.fixed;
  const usage_reports_image = data.desktop10.childImageSharp.fixed;
  const student_performance_reports_image = data.desktop11.childImageSharp.fixed;
  const pushed_assignments_image = data.desktop12.childImageSharp.fixed;


  // const create_your_own_problem_image = feature_images_data.create_your_own_problem.childImageSharp.fixed;
  // const upload_student_work_image = feature_images_data.upload_student_work.childImageSharp.fixed;
  // const standard_maps_image = feature_images_data.standard_maps.childImageSharp.fixed;
  // const print_image = feature_images_data.print.childImageSharp.fixed;
  // const integrations_image = feature_images_data.integrations.childImageSharp.fixed;
  // const create_assignment_image = feature_images_data.create_assignment.childImageSharp.fixed;
  // const tests_image = feature_images_data.tests.childImageSharp.fixed;
  // const automated_test_corrections_image = feature_images_data.automated_test_corrections.childImageSharp.fixed;
  // const problem_types_image = feature_images_data.problem_types.childImageSharp.fixed;
  // const usage_reports_image = feature_images_data.usage_reports.childImageSharp.fixed;
  // const student_performance_reports_image = feature_images_data.student_performance_reports.childImageSharp.fixed;
  // const pushed_assignments_image = feature_images_data.pushed_assignments.childImageSharp.fixed;

  const the_features = [
    {
      title: "Create Your Own Problem",
      body: "Customize assignments by creating your own questions. Answers can be multiple choice, free response, guided sentence, or paragraph form.",
      screenshot: create_your_own_problem_image,
      video_key: "NewGatsbyClipCreateYourOwnProblem",
      video_time: "0:36",
      target: "create_your_own_problem",
    },
    {
      title: "Upload Student Work",
      screenshot: upload_student_work_image,
      video_key: "NewGatsbyClipUploadStudentWork",
      video_time: "0:28",
      body: "This INTEGRAL feature serves both as a cheat-prevention tool and an opportunity for teachers and admins to take a deeper dive on student misconceptions. Students upload one or more images from their computer or phone to their assignments. Teachers can then download a PDF of student work for each assignment and/or export all student work in a single file.",
      target: "upload_student_work",
    },
    {
      title: "Standard Maps",
      body: "DeltaMath modules are aligned to state standards for the US and Canada, including AP (Calculus and Precalculus). Teachers can sort skills by standards when creating assignments, tests, or test corrections to quickly find appropriate skills to match their curriculum.",
      screenshot: standard_maps_image,
      video_key: "StandardMaps-v2",
      video_time: "0:18",
      target: "standard_maps",
    },
    {
      title: "Print",
      body: "Print to PDF, available with INTEGRAL, allows teachers to export any DeltaMath assignment to PDF. An intuitive print preview interface allows teachers to customize the assignment to support student needs in the classroom, with full solutions available to students via a QR code.",
      screenshot: print_image,
      video_key: "feature_Print_to_PDF",
      video_time: "0:32",
      target: "print",
    },
    {
      title: "Integrations",
      body: "Integrate with Google Classroom, Canvas, Schoology, Clever, and ClassLink, including SSO, grade passback, and rostering.",
      screenshot: integrations_image,
      video_key: "DeltaMath-Integrations",
      video_time: "0:25",
      target: "integrations",
    },
    {
      title: "Videos",
      body: "DeltaMath has targeted instructional videos for all of our 1800+ modules, allowing students to access instruction or remediation anytime, anywhere. Additionally, teachers can assign videos to students and see how much of a video each student has watched.",
      screenshot: videos_image,
      video_key: "NewGatsbyClipVideos",
      video_time: "0:27",
      target: "videos",
    },
    {
      title: "Tests/Test Corrections",
      body: "Premium teacher-users can create tests by mixing and matching DeltaMath skills, assigning problem subtypes or specific questions, and creating problems of their own, quickly assessing student performance and understanding. During a test, teachers can restrict student activity on the DeltaMath student page and can use passcodes to lock down test access. Following the test, teachers can automatically create test corrections, with each student receiving a targeted, individualized review assignment based on their results.",
      screenshot: automated_test_corrections_image,
      video_key: "NewGatsbyClipCreateTestsandCorrections",
      video_time: "0:38",
      target: "automated_test_corrections",
    },
    {
      title: "Problem Subtypes",
      body: "The majority of DeltaMath modules are further categorized into problem subtypes which allows teachers to assign distinct sections of a skill to align with classroom instruction.",
      screenshot: problem_types_image,
      video_key: "feature_Problem_Subtypes",
      video_time: "0:20",
      target: "problem_types",
    },
    {
      title: "Integral Admin Portal",
      screenshot: usage_reports_image,
      video_key: "feature_Admin_Portal",
      video_time: "1:04",
      body: "Detailed usage reports allow district or site admins to view student usage over time. Performance reports are also available, with our new fluency metric — derived from comparisons against seven million student-users nationwide — providing a data snapshot for any given standard, allowing for detailed tracking over the course of the semester or year. Admins can also create real-time apples-to-apples diagnostics or shared assessments by pushing assignments to specific teachers or sections, gathering further data and guiding future instruction.",
      target: "integral_admin_portal",
    },
    {
      title: "Student Performance Reports",
      body: "With Student Performance Reports, INTEGRAL site administrators can see detailed student performance data by teacher or course section. Our new fluency metric — derived from comparisons against seven million student-users nationwide — provides a data snapshot for any given standard, allowing for detailed tracking over the course of the semester or year.",
      screenshot: student_performance_reports_image,
      video_key: "feature_Admin_Portal",
      video_time: "1:04",
      target: "student_performance_reports",
    },
    {
      title: "Diagnostics and Shared Assessments",
      body: "INTEGRAL site admins can create real-time apples-to-apples diagnostics and/or shared assessments by pushing assignments to specific teachers or sections. Assignment data and student performance by standard provide insight into student strengths and weaknesses, which can then guide future instruction.",
      screenshot: pushed_assignments_image,
      video_key: "feature_Admin_Portal",
      video_time: "1:04",
      target: "pushed_assignments",
    },
    {
      title: "Advanced Assignment Options",
      screenshot: create_assignment_image,
      video_key: "NewGatsbyClipAdvancedAssignmentOptions",
      video_time: "0:31",
      body: "Create personalized assignments to match your class rigor and curriculum.  Teachers can mix and match skills, control when assignments are posted and due and, with a subscription account, offer late credit, assign videos, problem subtypes or specific questions, and assign to groups and/or individuals.",
      target: "advanced_assignment_options",
    }, 
    {
      title: "Co-Teachers",
      body: "Teachers on our premium licenses can add or in turn be added to other teachers' sections as co-teachers.",
      target: "co_teachers",
    },
    {
      title: "Folder/File System",
      body: "Teachers using our premium licenses have access to a folder/file system, allowing for greater organizational flexibility when it comes to tests and assignments.",
      target: "folder_file_system",
    },
    {
      title: "Plus Admin Portal",
      body: "Admins on our PLUS site licenses have the ability to add teachers and see broad-brush usage and student performance data.",
      target: "plus_admin_portal",
    }
  ];

  return (
    <div className="teachers__featuresContainer">
      {the_features.map((feature) => (
        <div
          className="teachers__featuresContainer__feature"
          id={feature.target}
          key={feature.target}
        >
          <h3><a className="teachers__featuresContainer__feature__back-to-top" href="#more-info"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 330 330"><path d="m325.606 229.393-150.004-150a14.997 14.997 0 0 0-21.213.001l-149.996 150c-5.858 5.858-5.858 15.355 0 21.213 5.857 5.857 15.355 5.858 21.213 0l139.39-139.393 139.397 139.393A14.953 14.953 0 0 0 315 255a14.95 14.95 0 0 0 10.607-4.394c5.857-5.858 5.857-15.355-.001-21.213z" /></svg></a>
            {feature.title}</h3>

          <div className="teachers__featuresContainer__feature_body">
            <div>
              <p>{feature.body}</p>

              {feature.video_key &&
                <VideoPopup videoOpened={shouldVideoBeOpened(feature.target)} title={feature.title} video_key={feature.video_key} video_time={feature.video_time} />
              }
            </div>

            {feature.screenshot && (
              <MediaContainer fullWidth>
                <Img fixed={feature.screenshot} alt={feature.title} />
              </MediaContainer>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default FeaturesContainer;
