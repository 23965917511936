import React from "react";
import { getDeltaMathAPI } from "../../utilities/utilities";
import { Button } from "react-md";

const ConfirmSchool = ({ user, setWrongSchool, setUpdateSubmitted }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const submitInfo = () => {
    setIsSubmitting(true);
    fetch(`${getDeltaMathAPI()}/teacher/account/update_school`, {
      body: JSON.stringify({
        ncesId: user.schoolinfo.nces_id ? user.schoolinfo.nces_id : undefined,
        schoolName: user.schoolinfo.name,
        streetAddress: user.schoolinfo.address,
        city: user.schoolinfo.city,
        state: user.schoolinfo.state,
        country: user.schoolinfo.country,
        zipCode: user.schoolinfo.zip,
        userType: user.schoolinfo.userType,
      }),
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (setUpdateSubmitted) {
          setUpdateSubmitted(true);
        }
      });
  };

  return (
    <>
      <div className="ss-row ss-row__top">
        <div className="school-info-display">
          <p className="school-info-display__name">
            {!user.schoolinfo.name
              ? `${user.first} ${user.last} ${
                  user.schoolinfo.userType
                    ? `(${user.schoolinfo.userType})`
                    : ""
                }`
              : user.schoolinfo.name}
          </p>
          <p>{user.schoolinfo.address}</p>
          <p>
            {user.schoolinfo.city}, {user.schoolinfo.state}{" "}
            {user.schoolinfo.zip}
          </p>
        </div>
      </div>
      <div className="ss-row">
        <a
          className="school-select-modal__not_my_school"
          onClick={() => setWrongSchool(true)}
        >
          {user.schoolinfo.userType
            ? "This information is incorrect"
            : "This is not my school"}
        </a>
      </div>

      <div className="ss-row">
        <Button className="school-info-button" onClick={submitInfo}>
          {isSubmitting
            ? "Submitting..."
            : user.schoolinfo.userType
              ? "Confirm Information"
              : "Confirm my school"}
        </Button>
      </div>
    </>
  );
};

export default ConfirmSchool;
