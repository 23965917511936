import React from "react";
import { Button } from "react-md";

function FormButtons(props) {
  return (
    <div className="teachers__formButtons">
      <div className="container teachers__formButtons-container">
        <div className="teachers__formButtons-half1">
          <p className="teachers__formButtons-half-title">Teachers:</p>
          <Button
            className="teachers__formButtons-half-button"
            theme="secondary"
            themeType="contained"
            onClick={props.enableCreate}
          >
            Create Account
          </Button>
        </div>
        <div className="teachers__formButtons-half2">
          <p className="teachers__formButtons-half-title">Schools/Districts:</p>
          <a href="#more-info">
            <Button
              className="teachers__formButtons-half-button"
              theme="secondary"
              themeType="contained"
            >
              More Info
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FormButtons;
