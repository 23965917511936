import { RiArrowDownSFill } from "@react-icons/all-files/ri/RiArrowDownSFill";
import React, { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";
import { Select, TextField, Button, Checkbox } from "react-md";
import {
  OUTSIDE_US_OPTIONS,
  US_STATES,
  US_STATES_CODES,
} from "../../constants/states";
import { getDeltaMathAPI } from "../../utilities/utilities";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../styles/components/teachers-schools/SchoolSelectModal.scss";

const SchoolSelectionForm = (props) => {
  const [state, setState] = React.useState("");
  const [schoolOrDistrictName, setSchoolOrDistrictName] = React.useState({
    id: "",
    name: "",
    address: {
      street: "",
    },
  });
  const [school, setSchool] = React.useState("");
  const [country, setCountry] = React.useState("United States");
  const [streetAddress, setStreetAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [timezone, setTimezone] = React.useState("");
  const [stateOrProvince, setStateOrProvince] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const typeaheadRef = React.createRef();
  const [isLoading, setIsLoading] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [outsideUS, setOutsideUS] = useState(false);
  const searchParams =
    window.location && window.location.search
      ? new URLSearchParams(window.location.search)
      : undefined;

  const getStateCode = () => {
    var i;
    US_STATES.forEach((currentState, index) => {
      if (currentState === state) {
        i = index - 1;
      }
    });
    return US_STATES_CODES[i];
  };

  const resetFormValues = () => {
    setSchoolOrDistrictName({ name: "" });
    setSchool("");
    setCountry("");
    setStreetAddress("");
    setCity("");
    setTimezone("");
    setPostalCode("");
  }

  const handleAutocompleteOnSelect = (item) => {
    setSchoolOrDistrictName(item);
    if (item.name && item.name.indexOf("not found") === -1) {
      setSchool(item.name);
      setValue("qrSchoolNametextfield", item.name);
      // let brokenAddress = schoolOrDistrictName.schoolAddress.split(",");
      setStreetAddress(item.address.street);
      setValue("qrStreetAddressTextfield", item.address.street);
      setCity(item.address.city);
      if (item.address) {
        setCountry("United States");
        setValue("qrCountryField", "United States");
        setValue("countrySelectField", "United States");
      } else {
        // can this be set other than selecting autocomplete?
        setValue("qrCountryField", "");
        setValue("countrySelectField", "");
      }
      setStateOrProvince(getStateCode());
      setValue("qrStateProvince", getStateCode());
      setValue("stateProvinceField", getStateCode());
      setPostalCode(item.address.zip);
    }
    else {
      setCountry("United States");
    }
  };

  const submitInfo = () => {
    fetch(`${getDeltaMathAPI()}/teacher/account/update_school`, {
      body: JSON.stringify({
        ncesId:
          schoolOrDistrictName.id === -1 ? undefined : schoolOrDistrictName.id,
        schoolName: school,
        streetAddress: streetAddress,
        city: city,
        state: stateOrProvince,
        country: country,
        zipCode: postalCode,
      }),
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (props.setUpdateSubmitted) {
          // const user = window.localStorage.getItem("user");
          // if (user) {
          //   window.localStorage.setItem(
          //     "user",
          //     JSON.stringify({
          //       ...user,
          //       "schoolinfo.updated_time": Math.floor(
          //         new Date().getTime() / 1000
          //       ),
          //     })
          //   );
          // }
          // const redirectUrl = searchParams.get("redirect")
          // if (redirectUrl) {
          //   window.location.href = redirectUrl;
          // }
          props.setUpdateSubmitted(true);
        }
      });
  }

  const handleSearch = (query) => {
    setIsLoading(true);

    fetch(
      getDeltaMathAPI() +
        `/digger_autocomplete?type=schools&state=${getStateCode()}&search=${query}`,
    )
      .then((resp) => resp.json())
      .then((items) => {
        let options = [];

        for (let i = 0; i < items.length; i++) {
          const theItem = items[i];
          const match = items.find(
            (i) => i.name === theItem.name && i.id !== theItem.id
          );
          options.push({
            id: theItem.id,
            name:
              theItem.name +
              (typeof match !== "undefined"
                ? " (" + theItem.address.city + ")"
                : ""),
            address: theItem.address,
          });
        }

        options.unshift({
          id: -1,
          name: "School not found?",
          address: { street: "" },
        });

        setSchoolOptions(options);
        setIsLoading(false);
      });
  };

  const {
    control,
    setValue,
    register,
    formState: { isSubmitting },
    getValues
  } = useForm();


  const handleStateOrProvinceChange = React.useCallback(
    (nextValue, _option) => {
      setStateOrProvince(nextValue);
    },
    []
  );

  const onFormSubmit = async (e, data) => {
    e.preventDefault();

    submitInfo()
  }

  const outsideOfUSToggle = () => {
    const newValue = !outsideUS;

    if (newValue) {
      setCountry("United States");
    }
    else {
      setCountry("");
    }

    setOutsideUS(newValue);

    resetFormValues();
  }

  const formReady = stateOrProvince && postalCode && country && school;

  const showButton =
    schoolOrDistrictName.id || outsideUS;

  return (
    <form
      className={`popup-form get-quote-form ${
        schoolOptions && schoolOptions.length > 0 ? "ss-xl-form" : ""
      }`}
      onSubmit={onFormSubmit}
    >
      <div className="ss-row ss-row__top">
        <div className="ss-row__col">
          <Controller
            control={control}
            name="qrStateField"
            defaultValue=""
            rules={{
              required: "Please select a state.",
            }}
            disabled={isSubmitting}
            render={(props) => (
              <Select
                id="custom-select-8"
                label="State"
                {...props}
                options={US_STATES.filter(
                  (x) => !OUTSIDE_US_OPTIONS.some((y) => y === x)
                )}
                disabled={isSubmitting || outsideUS}
                value={state}
                onChange={(e) => {
                  resetFormValues();
                  setState(e);
                  setStateOrProvince(
                    US_STATES_CODES[US_STATES.findIndex((x) => x === e) - 1]
                  );
                }}
                disableLeftAddon={false}
                rightChildren={<RiArrowDownSFill className="dropDownArrow" />}
              />
            )}
          />
          <Checkbox
            id="outside-us"
            name="outsideUS"
            className="outside_us_checkbox"
            label="I'm outside the US"
            value={outsideUS}
            onClick={outsideOfUSToggle}
          />
        </div>
        {OUTSIDE_US_OPTIONS.indexOf(state) === -1 &&
        state !== "" &&
        !outsideUS ? (
          <>
            <div id="schoolOrDistrictName-column" className="ss-row__col">
              <AsyncTypeahead
                id="qrSchoolOrDistrictName-text-field"
                labelKey="name"
                ref={typeaheadRef}
                inputProps={{
                  name: "autocomplete-school-or-district",
                }}
                minLength={4}
                isLoading={isLoading && false}
                onChange={(text, e) => {
                  if (text.length > 0) handleAutocompleteOnSelect(text[0]);
                }}
                filterBy={() => true}
                onSearch={handleSearch}
                options={schoolOptions}
                placeholder={"Search school here..."}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      {schoolOrDistrictName.id && schoolOrDistrictName.id !== -1 && (
        <div className="ss-row">
          <div className="school-info-display">
            <p className="school-info-display__name">
              {schoolOrDistrictName.name}
            </p>
            <p>{schoolOrDistrictName.address.street}</p>
            <p>
              {schoolOrDistrictName.address.city}, {state}{" "}
              {schoolOrDistrictName.address.zip}
            </p>
          </div>
        </div>
      )}

      {(state !== "" && schoolOrDistrictName.name.includes("not found")) ||
      outsideUS ? (
        <>
          <div id="school-row-id-2" className="ss-row">
            <TextField
              id="schoolNametextfield"
              name="qrSchoolNametextfield"
              className="ss-row__full"
              label="School Name"
              onChange={(e) => setSchool(e.target.value)}
              value={school}
              ref={register({
                required: "Please enter a school name.",
              })}
              disabled={isSubmitting}
            />
          </div>
          <div id="school-row-id-4" className="ss-row">
            <div className="ss-row__col">
              <TextField
                id="custom-country-text-field"
                name="qrCountryField"
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                disabled={isSubmitting || !outsideUS}
              />
            </div>

            <div id="custom-city-column" className="ss-row__col">
              <TextField
                id="custom-city-text-field"
                name="customCityTextField"
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div id="school-row-id-6" className="ss-row">
            {outsideUS || OUTSIDE_US_OPTIONS.indexOf(state) > 0 ? (
              <div className="ss-row__col">
                <TextField
                  id="custom-state-provice-text-field"
                  name="qrStateProvince"
                  label="State/Province"
                  value={stateOrProvince}
                  onChange={(e) => setStateOrProvince(e.target.value)}
                  disabled={isSubmitting || !outsideUS}
                />
              </div>
            ) : (
              <div className="ss-row__col">
                <Controller
                  control={control}
                  name="qrStateProvince"
                  defaultValue=""
                  rules={{
                    required: "Please select a state/province.",
                  }}
                  disabled={
                    isSubmitting || OUTSIDE_US_OPTIONS.indexOf(state) === -1
                  }
                  render={(props) => (
                    <Select
                      id="custom-select-10"
                      label="State/Province"
                      {...props}
                      options={US_STATES_CODES}
                      value={stateOrProvince}
                      disabled={true}
                      onChange={(e) => {
                        props.onChange(e);
                        handleStateOrProvinceChange(e);
                      }}
                      disableLeftAddon={false}
                      rightChildren={
                        <RiArrowDownSFill className="dropDownArrow" />
                      }
                    />
                  )}
                />
              </div>
            )}

            <div id="custom-postalCode-column" className="ss-row__col">
              <TextField
                id="postalCode-text-field"
                name="postalCodeTextField"
                label="Postal Code"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                disabled={isSubmitting}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {showButton && (
        <Button
          className="school-info-button"
          type="submit"
          disabled={!formReady}
        >
          {isSubmitting ? "Submitting..." : "Declare my school"}
        </Button>
      )}
    </form>
  );
};

export default SchoolSelectionForm;
