import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export default function Hero() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "images/Teachers Page/Rectangle 3.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;
  return (
    // <div className="teachers__hero">
    <BackgroundImage
      Tag="section"
      className="teachers__hero"
      fluid={imageData}
      backgroundColor={`#303d4e`}
    >
      <div className="container teachers__hero-shadow">
        <div>
          <h2 className="teachers__hero-sub-header">DeltaMath for</h2>
        </div>
        <h1 className="teachers__hero-header">
          Teachers&#x200a;/&#x200a;Schools
        </h1>
      </div>
    </BackgroundImage>
    // </div>
  );
}
