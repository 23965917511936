import React from "react";
import ReactPlayer from "react-player";
import Plans from "./Plans";

function PlansContainer(props) {
  return (
    <div className="teachers__plansContainer">
      <div className="container teachers__plansContainer-container">

        <h1 id="more-info" className="teachers__plansContainer-header">
          See all you can do with DeltaMath!
        </h1>

        <ReactPlayer
          url={`https://videos.deltamath.com/public/feature_All_You_Can_Do/Default/HLS/feature_All_You_Can_Do.m3u8`}
          controls
          light={'/files/deltamath-video-placeholder.png'}
          playing={true}
          config={{
            file: {
              hlsOptions: {
                forceHLS: true,
              },
              attributes: { crossOrigin: 'anonymous' },
              tracks: [
                {
                    label: "English",
                    kind: 'subtitles', 
                    src: 'https://videos.deltamath.com/public/feature_All_You_Can_Do/Default/Subtitles/feature_All_You_Can_Do.mp4.vtt', 
                    srcLang: 'en', 
                    default: false
                },
              ]
            }
          }}
          style={
            {
              margin: "0 auto 3rem auto",
              maxWidth: "100%",
            }
          }
        />

        <h1 id="more-info" className="teachers__plansContainer-header">
          Which license is right for you?
        </h1>
        <Plans enableQuote={props.enableQuote} />
      </div>
    </div>
  );
}

export default PlansContainer;
