import React from "react";
import { VscChromeClose } from "@react-icons/all-files/vsc/VscChromeClose";
import { Dialog, DialogContent, DialogFooter } from "@react-md/dialog";
import { useToggle } from "@react-md/utils";
import "../../styles/components/teachers-schools/VideoPopup.scss";

import ReactPlayer from "react-player";

function VideoPopup(props) {
    const [visible, enable, disable] = useToggle(props.videoOpened);
    
    React.useEffect(() => {
        const overlay = document.getElementById("simple-dialog-overlay");
        if (
            overlay !== undefined &&
            overlay !== null
        ) {
            overlay.style.backgroundColor = "rgba(0, 0, 0, 0.25)";
        }
    }, [visible]);

    return (
        <div>
            <p className="teachers__featuresContainer__feature__see_how" onClick={enable}>
                <svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m9.57 17.636 8.065-5.136L9.57 7.365v10.27Zm2.93 6.947c-1.651 0-3.212-.317-4.682-.951a12.256 12.256 0 0 1-3.852-2.598 12.256 12.256 0 0 1-2.598-3.852A11.692 11.692 0 0 1 .417 12.5c0-1.671.317-3.242.951-4.712.635-1.47 1.5-2.75 2.598-3.837a12.386 12.386 0 0 1 3.852-2.583C9.288.734 10.848.417 12.5.417c1.671 0 3.242.317 4.712.951a12.203 12.203 0 0 1 3.837 2.583 12.201 12.201 0 0 1 2.583 3.837c.634 1.47.951 3.04.951 4.712 0 1.652-.317 3.212-.951 4.682a12.385 12.385 0 0 1-2.583 3.852 12.076 12.076 0 0 1-3.837 2.598c-1.47.634-3.04.951-4.712.951Zm0-1.812c2.86 0 5.286-1.002 7.28-3.006 1.994-2.004 2.99-4.425 2.99-7.265 0-2.86-.996-5.286-2.99-7.28-1.994-1.994-4.42-2.99-7.28-2.99-2.84 0-5.261.996-7.265 2.99C3.23 7.214 2.229 9.64 2.229 12.5c0 2.84 1.002 5.261 3.006 7.265 2.004 2.004 4.425 3.006 7.265 3.006Z" fill="#4893BA" /></svg>
                <span className="teachers__featuresContainer__feature__see_how__text">See how it works</span>
                {props.video_time && <span className="teachers__featuresContainer__feature__see_how__time">({props.video_time})</span>}
            </p>
            <Dialog
                id="video-popup-dialog"
                visible={visible}
                onRequestClose={disable}
                aria-labelledby="dialog-title"
                className="videoPopupCardDialog"
            >
                <DialogFooter align="between">
                    <h2>{props.title}</h2>

                    <VscChromeClose
                        className="dialog-close1"
                        id="dialog-close"
                        onClick={disable}
                    />
                </DialogFooter>
                <DialogContent className="videoPopup-dialogContent">
                    <ReactPlayer
                        url={`https://videos.deltamath.com/public/${props.video_key}/Default/HLS/${props.video_key}.m3u8`}
                        controls
                        playing
                        config={{
                            file: {
                              hlsOptions: {
                                forceHLS: true,
                              },
                              attributes: { crossOrigin: 'anonymous' },
                              tracks: [
                                {
                                    label: "English",
                                    kind: 'subtitles', 
                                    src: `https://videos.deltamath.com/public/${props.video_key}/Default/Subtitles/${props.video_key}.mp4.vtt`,
                                    srcLang: 'en', 
                                    default: false
                                },
                              ]
                            }
                          }}    
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default VideoPopup;
