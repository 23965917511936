import React, { useState } from "react";

import Layout from "../components/Layout/Layout";

import Hero from "../components/TeachersSchools/Hero";
import FormButtons from "../components/TeachersSchools/FormButtons";
import PlansContainer from "../components/TeachersSchools/PlansContainer";
import FeaturesContainer from "../components/TeachersSchools/FeaturesContainer";
import CreateTeacherForm from "../components/TeachersSchools/CreateTeacherForm";
import QuoteForm from "../components/TeachersSchools/QuoteForm";
import GoogleOrEmailSignUp from "../components/shared/GoogleOrEmailSignUp";
import SchoolSelectModal from "../components/TeachersSchools/SchoolSelectModal";

import { useToggle } from "@react-md/utils";

import "../styles/pages/teachersSchools.scss";
import "../styles/components/Layout/layout.scss";
import "../styles/pages/responsiveness.scss";

export default function TeachersSchools(props) {
  if (props?.location?.search) {
    props.location.search = props.location.search.replace(/amp;/g, "");
  }

  // auto-open create account modal based on url
  // auto-open the Create Quote Modal based on URL: /teachers-schools/?createQuote=true
  const searchParams =
    props && props.location && props.location.search
      ? new URLSearchParams(props.location.search)
      : undefined;

  const openSchoolSelect = searchParams
    ? !!searchParams.get("schoolSelect")
    : false;

  const openSignUp = searchParams ? !!searchParams.get("createAccount") : false;
  const openQuote = searchParams ? !!searchParams.get("createQuote") : false; 
  const license = searchParams ? searchParams.get("license") : undefined;
  const licenseTier = searchParams
    ? searchParams.get("licenseTier")
    : undefined;
  const nces = searchParams ? searchParams.get("nces") : undefined;
  const name = searchParams ? searchParams.get("name") : false; 
  const email = searchParams ? searchParams.get("email") : undefined;
  const lms = searchParams ? searchParams.get("lms") : undefined;
  const quoteType = searchParams ? searchParams.get("quoteType") : undefined;
  const licenseCount = searchParams
    ? searchParams.get("licenseCount")
    : undefined;
  const noCompare = searchParams?.get("noCompare") === "true";
  const [visibleCreate, enableCreate, disableCreate] = useToggle(false);
  const [visibleQuote, enableQuote, disableQuote] = useToggle(openQuote);
  const [visibleSignUpType, enableSignUpType, disableSignUpType] =
    useToggle(openSignUp);
  const [accountData, setAccountData] = useState(null);
  const [killQueryParamData, setKillQueryParamData] = useState(false);
  
  const openEmptyQuote = () => {
    setKillQueryParamData(true);
    enableQuote();
  }

  const handleSignUpTypeSelection = (payload) => {
    const { selection, data, target } = payload;

    if (selection === "google") setAccountData(data);

    // open correct modal
    if (target === "teacher") enableCreate();
  };

  return (
    <Layout>
      <Hero />
      <FormButtons
        enableCreate={enableSignUpType}
        enableQuote={openEmptyQuote}
      />
      <PlansContainer enableQuote={openEmptyQuote} />
      <FeaturesContainer searchParams={searchParams} />
      <CreateTeacherForm
        visibleCreate={visibleCreate}
        enableCreate={enableCreate}
        disableCreate={disableCreate}
        accountData={accountData}
      />
      <p className="nationalListPricing"><a href="/files/DeltaMath-National-List-Pricing.pdf">National List Pricing</a></p>
      <SchoolSelectModal visible={openSchoolSelect} />
      <QuoteForm
        visibleQuote={visibleQuote}
        enableQuote={enableQuote}
        disableQuote={disableQuote}
        nces={!killQueryParamData ? nces : undefined}
        license={!killQueryParamData ? license : undefined}
        licenseTier={!killQueryParamData ? licenseTier : undefined}
        name={!killQueryParamData ? name : undefined}
        email={!killQueryParamData ? email : undefined}
        lms={!killQueryParamData ? lms : undefined}
        quoteType={!killQueryParamData ? quoteType : undefined}
        licenseCount={!killQueryParamData ? licenseCount : undefined}
        noCompare={!killQueryParamData ? noCompare : false}
      />
      {visibleSignUpType ? (
        <GoogleOrEmailSignUp
          visible={visibleSignUpType}
          enable={enableSignUpType}
          disable={disableSignUpType}
          selection={handleSignUpTypeSelection}
          target="teacher"
        />
      ) : (
        <></>
      )}
      <div
        className="g-recaptcha"
        data-sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
      ></div>
    </Layout>
  );
}
